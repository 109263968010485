/* You can add global styles to this file, and also import other style files */
@use '@nationwide-bolt/css-core/dist/css/bolt-core';
@import '@nationwide-bolt/adapter-material/dist/bolt-material-theme.css';
@import '@nationwide-bolt/adapter-material/dist/bolt-material-theme';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
